<template>
  <div class="main">
    <van-dropdown-menu class="alarmDropdown">
      <van-dropdown-item v-model="state.alert_type" :options="alertTypeList" />
      <van-dropdown-item v-model="state.unit_id" :options="unitList" />
    </van-dropdown-menu>
    <div class="skeletonBox" v-if="AlarmList.length > 0">
      <div
        class="skeletonListBox van-skeleton--animate"
        v-for="items in AlarmList"
        :key="items.id"
      >
        <div class="skeletonLeft">
          <div
            class="skeletonIcon"
            :style="{
              'background-color':
                items.tempalert_type == 1
                  ? '#F9C81C'
                  : items.tempalert_type == 2 && items.tempstatus == 1
                  ? '#F9371C'
                  : items.tempalert_type == 2 && items.tempstatus == 2
                  ? '#2C64F2'
                  : items.tempalert_type == 2 && items.tempstatus == 3
                  ? '#00CBFF'
                  : items.tempalert_type == 2 && items.tempstatus == 4
                  ? '#2FC8AC'
                  : '#cccccc'
            }"
          >
            <img
              :src="
                items.tempalert_type == 1
                  ? require('../../../assets/img/yj.png')
                  : items.tempalert_type == 2 && items.tempstatus == 1
                  ? require('../../../assets/img/bj.png')
                  : items.tempalert_type == 2 && items.tempstatus == 2
                  ? require('../../../assets/img/ptpc.png')
                  : items.tempalert_type == 2 && items.tempstatus == 3
                  ? require('../../../assets/img/zzpc.png')
                  : items.tempalert_type == 2 && items.tempstatus == 4
                  ? require('../../../assets/img/wc.png')
                  : ''
              "
            />
          </div>
        </div>
        <div class="skeletonRight">
          <div class="title">{{ items.factor_name }}</div>
          <div class="content">
            <div class="cont_main">开始时间：{{ items.alert_date }}</div>
            <div class="cont_main">监测值：{{ items.monitor_value }}</div>
            <div class="cont_main">装置：{{ items.unit_id }}</div>
            <div class="cont_main">状态：{{ items.alert_type }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else :description="OutletDescription" />
  </div>
</template>
<script>
import { reactive, onMounted, watch, ref } from "vue";
import Http from "../../../api/http";
export default {
  setup() {
    const state = reactive({
      alert_type: 2,
      unit_id: "",
      method: "list",
      table: "MON_ALERT"
    });
    const unitList = ref([]);
    const alertTypeList = ref([]);
    const OutletDescription = ref("加载中....");
    const AlarmList = ref([]);
    //获取装置下拉
    const getUnit = () => {
      Http.get(
        {
          method: "query",
          queryId: 28
        },
        false,
        true
      )
        .then(res => {
          var defaultList = [{ text: "全部装置", value: "" }];
          res.map.forEach(elem => {
            defaultList.push({ text: elem.label, value: elem.value });
          });
          unitList.value = defaultList;
        })
        .catch(() => {});
    };
    // 获取状态下拉
    const getoutstatusList = () => {
      Http.get(
        {
          prop: "alert_type",
          method: "listPardata",
          table: "MON_ALERT"
        },
        false,
        true
      )
        .then(res => {
          var defaultList = [{ text: "全部状态", value: "" }];
          res.forEach(elem => {
            defaultList.push({ text: elem.label, value: elem.value });
          });
          alertTypeList.value = defaultList;
        })
        .catch(() => {});
    };
    // 获取报警列表
    const getAlarmList = () => {
      AlarmList.value = [];
      OutletDescription.value = "加载中...";
      Http.get(state, false, true)
        .then(res => {
          if (res.map.length > 0) {
            AlarmList.value = res.map;
          } else {
            OutletDescription.value = "暂无数据";
          }
        })
        .catch(() => {});
    };
    watch(
      () => [state.unit_id, state.alert_type],
      ([newunit, oldunit], [newtype, oldtype]) => {
        if (newunit != oldunit) {
          getAlarmList();
        }
        if (newtype != oldtype) {
          getAlarmList();
        }
      }
    );

    onMounted(() => {
      getUnit();
      getAlarmList();
      getoutstatusList();
    });
    return {
      state,
      unitList,
      alertTypeList,
      OutletDescription,
      AlarmList,
      getUnit,
      getAlarmList
    };
  }
};
</script>
